@import "~@wpa/styles/scss/colours.scss";
@import "~@wpa/styles/scss/boxFrame.scss";

//  Desktop4
//  Desktop9
//  Desktop10
//  Desktop12
//  Desktop22
//  Desktop24
//  Desktop27
//  Desktop28
html {
	background: $background-secondary url("/images/desktop24.jpg") no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

body    {
	overflow-y:scroll;
	background-color: transparent;
}

// .fade-enter {
// 	opacity: 0.01;
// }

// .fade-enter.fade-enter-active {
// 	opacity: 1;
// 	transition: opacity 1500ms ease-in;
// }

#footerMenu {
	color: $gray-500;
	list-style: none;
	margin: 1rem 0;
	text-align: center;
}

#footerMenu li {
	display: inline;
	padding-right: 0.5rem;
}

// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
