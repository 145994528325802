@import "./colours.scss";

// @import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');
@font-face {
	font-family: 'Bebas';
	src:url('Bebas.ttf.woff') format('woff'),
	url('Bebas.ttf.svg#Bebas') format('svg'),
	url('Bebas.ttf.eot'),
	url('Bebas.ttf.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
	unicode-range: U+0000-001F, U+0021-007F;
}

body {
	background: none;
}

.ant-layout {
	background: none;
}

.ant-layout-content {
	margin: 1em;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $headerFont;
}

/*
 *  	Shared spinner
 */
.spinner {
	margin: 2em;
	width: 70px;
	height: 40px;
	text-align: center;
	font-size: 10px;
	display:block;
	> span {
		background-color: $color-neutral;
		height: 100%;
		width: 6px;
		display: inline-block;

		-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
		animation: sk-stretchdelay 1.2s infinite ease-in-out;
	}
	.rect2 {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
	}
	.rect3 {
		-webkit-animation-delay: -1.0s;
		animation-delay: -1.0s;
	}
	.rect4 {
		-webkit-animation-delay: -0.9s;
		animation-delay: -0.9s;
	}
	.rect5 {
		-webkit-animation-delay: -0.8s;
		animation-delay: -0.8s;
	}
}

.inlineSpinner {
	margin: 0 0.75em 0 0;
	height: 2em;
	width:auto;
	float:left;
	> span {
		width: 3px;
		margin: 0 1px;
	}
}

.lightSpinner {
	> span {
		background-color: $color-light;
	}
}

@-webkit-keyframes sk-stretchdelay {
	0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
	20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
	0%, 40%, 100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}  20% {
		   transform: scaleY(1.0);
		   -webkit-transform: scaleY(1.0);
	   }
}


/*
 *		Form default
 */
.ant-row {
	margin-bottom: 1rem;
}
.ant-form-item-label {
	line-height: 2em;
}
.ant-btn {
	font-family: "Bebas", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 400;
	border:0;
	letter-spacing: 1;
}
.ant-btn + .ant-btn{
	margin-left: 0.5em;
}

.ant-btn-secondary {
	background: $color-complement-0;
	color: #fff;
}
.ant-btn-secondary:hover,
.ant-btn-secondary:focus,
.ant-btn-secondary:active {
	background: $color-complement-1;
	color: #fff;
}


/*
 *		Global helpers
 */
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.text-justify {
	text-align: justify;
}

ul.list-unstyled {
	list-style: none;
	padding-left: 0;
	margin-left: 0;
}

.btn .icon {
	padding-right: 0.5rem;
	// FA sets it to 0, so we need to overrride it for buttons
	vertical-align: middle !important;
}
