

//  Safe to use on a .col as we only add top and bottom padding
//  The sides are done for consistency on other items
.boxFrame {
	padding: 1em;
	background: rgba(255,255,255,0.5);
	> div {
		background: rgba(255,255,255,0.5);
		border: 9px solid $color-neutral;
		padding:2em;
	}
}