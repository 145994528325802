// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=35k0u0kv8uQj8BIojvWw7o3CgiT */

// Feel free to copy&paste color codes to your application */

// As hex codes */
//
//$color-primary-0: #D40656; // Main Primary color */
//$color-primary-1: #E45C90;
//$color-primary-2: #DC3576;
//$color-primary-3: #A60040;
//$color-primary-4: #820032;
//
//$color-secondary-1-0: #05AF4F; // Main Secondary color (1) */
//$color-secondary-1-1: #4FC583;
//$color-secondary-1-2: #2CB568;
//$color-secondary-1-3: #00893B;
//$color-secondary-1-4: #006B2F;
//
//$color-secondary-2-0: #D4F006; // Main Secondary color (2) */
//$color-secondary-2-1: #E9FA65;
//$color-secondary-2-2: #E2F83C;
//$color-secondary-2-3: #A5BB00;
//$color-secondary-2-4: #829300;

// As RGBa codes */

//$rgba-primary-0: rgba(212,  6, 86,1);	// Main Primary color */
//$rgba-primary-1: rgba(228, 92,144,1);
//$rgba-primary-2: rgba(220, 53,118,1);
//$rgba-primary-3: rgba(166,  0, 64,1);
//$rgba-primary-4: rgba(130,  0, 50,1);
//
//$rgba-secondary-1-0: rgba(  5,175, 79,1);	// Main Secondary color (1) */
//$rgba-secondary-1-1: rgba( 79,197,131,1);
//$rgba-secondary-1-2: rgba( 44,181,104,1);
//$rgba-secondary-1-3: rgba(  0,137, 59,1);
//$rgba-secondary-1-4: rgba(  0,107, 47,1);
//
//$rgba-secondary-2-0: rgba(212,240,  6,1);	// Main Secondary color (2) */
//$rgba-secondary-2-1: rgba(233,250,101,1);
//$rgba-secondary-2-2: rgba(226,248, 60,1);
//$rgba-secondary-2-3: rgba(165,187,  0,1);
//$rgba-secondary-2-4: rgba(130,147,  0,1);

// Generated by Paletton.com Â© 2002-2014 */
// http://paletton.com */



// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=43m0u0k++Kl-9ZUUC+T++vk+Uqh */

// Feel free to copy&paste color codes to your application */


// As hex codes */

$color-primary-0: #00ACCC;	// Main Primary color */
$color-primary-1: #05D5FC;
$color-primary-2: #01D7FF;
$color-primary-3: #00819A;
$color-primary-4: #006D81;

$color-secondary-1-0: #FFB000;	// Main Secondary color (1) */
$color-secondary-1-1: #FFB000;
$color-secondary-1-2: #FFB000;
$color-secondary-1-3: #FAAC00;
$color-secondary-1-4: #D19000;

$color-secondary-2-0: #FF1800;	// Main Secondary color (2) */
$color-secondary-2-1: #FF1800;
$color-secondary-2-2: #FF1800;
$color-secondary-2-3: #FA1800;
$color-secondary-2-4: #D11400;

$color-complement-0: #FF7C00;	// Main Complement color */
$color-complement-1: #FF9C20;
$color-complement-2: #FF7C00;
$color-complement-3: #FA7A00;
$color-complement-4: #D16600;



// As RGBa codes */

$rgba-primary-0: rgba(  0,172,204,1);	// Main Primary color */
$rgba-primary-1: rgba(  5,213,252,1);
$rgba-primary-2: rgba(  1,215,255,1);
$rgba-primary-3: rgba(  0,129,154,1);
$rgba-primary-4: rgba(  0,109,129,1);

$rgba-secondary-1-0: rgba(255,176,  0,1);	// Main Secondary color (1) */
$rgba-secondary-1-1: rgba(255,176,  0,1);
$rgba-secondary-1-2: rgba(255,176,  0,1);
$rgba-secondary-1-3: rgba(250,172,  0,1);
$rgba-secondary-1-4: rgba(209,144,  0,1);

$rgba-secondary-2-0: rgba(255, 24,  0,1);	// Main Secondary color (2) */
$rgba-secondary-2-1: rgba(255, 24,  0,1);
$rgba-secondary-2-2: rgba(255, 24,  0,1);
$rgba-secondary-2-3: rgba(250, 24,  0,1);
$rgba-secondary-2-4: rgba(209, 20,  0,1);

$rgba-complement-0: rgba(255,124,  0,1);	// Main Complement color */
$rgba-complement-1: rgba(255,124,  0,1);
$rgba-complement-2: rgba(255,124,  0,1);
$rgba-complement-3: rgba(250,122,  0,1);
$rgba-complement-4: rgba(209,102,  0,1);



// Generated by Paletton.com Â© 2002-2014 */
// http://paletton.com */


$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;


$grays: () !default;
$grays: map-merge((
		100: $gray-100,
		200: $gray-200,
		300: $gray-300,
		400: $gray-400,
		500: $gray-500,
		600: $gray-600,
		700: $gray-700,
		800: $gray-800,
		900: $gray-900
), $grays);





$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;



$colors: () !default;
$colors: map-merge((
		blue: $blue,
		indigo: $indigo,
		purple: $purple,
		pink: $pink,
		red: $red,
		orange: $orange,
		yellow: $yellow,
		green: $green,
		teal: $teal,
		cyan: $cyan,
		white: $white,
		gray: $gray-600,
		gray-dark: $gray-800
), $colors);

$color-neutral: #333;
$color-neutral-light: #ccc;
$color-light: #fff;
$color-dark: #000;

$background-primary: #fff;
$background-secondary: #333;



$primary:       $color-primary-0 !default;
$secondary:     $color-complement-0 !default;
$success:       $green !default;
$info:          $color-secondary-1-0 !default;
$warning:       $color-secondary-1-0 !default;
$danger:        $color-secondary-2-4 !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge((
		"primary":    $primary,
		"secondary":  $secondary,
		"success":    $success,
		"info":       $info,
		"warning":    $warning,
		"danger":     $danger,
		"light":      $light,
		"dark":       $dark
), $theme-colors);




$color-status-idea: $color-secondary-1-3;
$color-status-outline: $color-complement-4;
$color-status-draft: $color-primary-0;
$color-status-published: $color-neutral;
$color-status-done: $color-neutral;



$headerFont: "Bebas", "Helvetica Neue", Helvetica, Arial, sans-serif;